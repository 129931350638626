import React, { useEffect } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Skeleton from 'antd/lib/skeleton';
import CardTopPoint from '@src/components/CardTopPoint';
import { useDispatch, useSelector } from 'react-redux';
import { actionTypes } from '@src/activities/activitiesAction';
import { actionTypes as actionTypesDashboard } from '@src/dashboard/dashboardAction';
import { IactivitiesState } from '@src/interface/IActivitiesState';
import { IAppState } from '@src/interface/IAppState';
import TopTrending from '@src/components/TopTrending';
import HeatmapMiniChart from '@src/components/Chart/HeatmapMiniChart';
import LateOffDay from '@src/components/LateOffDay';
import CardDashboard from '@src/components/CardDashboard/CardDashboard';
import { getTypeOfPost } from '@src/utils/getTypeOfPost';
import CardStatusDashboard from '@src/components/CardDashboard/CardStatusDashboard';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({ type: actionTypes.REQUEST_DATA_POINT_GIVEN });
    dispatch({ type: actionTypes.REQUEST_DATA_POINT_RECEIVER });
  }, [dispatch]);
  const { givenData, receiversData }: IactivitiesState = useSelector(
    (state: IAppState) => state.activitiesState
  );

  const newestPosts = useSelector((state: IAppState) => state.newestPostsState);
  useEffect(() => {
    dispatch({ type: actionTypesDashboard.GET_NEWEST_POSTS_REQUEST });
  }, [dispatch]);

  return (
    <Row gutter={[20, 20]}>
      <Col span={16}>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <HeatmapMiniChart />
              </Col>
            </Row>
            {newestPosts.isLoading ? (
              <Skeleton active />
            ) : (
              newestPosts.data.map((item, index) =>
                index % 2 !== 1 ? (
                  item.kind !== 2 ? (
                    <Row key={index} style={{ marginBottom: 20 }}>
                      <Col span={24}>
                        <CardDashboard posts={item} cardType={getTypeOfPost(item.kind)} />
                      </Col>
                    </Row>
                  ) : (
                    <Row key={index} style={{ marginBottom: 20 }}>
                      <Col span={24}>
                        <CardStatusDashboard posts={item} cardType={getTypeOfPost(item.kind)} />
                      </Col>
                    </Row>
                  )
                ) : null
              )
            )}
          </Col>
          <Col span={12}>
            <Row style={{ marginBottom: 20 }}>
              <Col span={24}>
                <LateOffDay />
              </Col>
            </Row>
            {newestPosts.isLoading ? (
              <Skeleton active />
            ) : (
              newestPosts.data.map((item, index) =>
                index % 2 === 1 ? (
                  item.kind !== 2 ? (
                    <Row key={index} style={{ marginBottom: 20 }}>
                      <Col span={24}>
                        <CardDashboard posts={item} cardType={getTypeOfPost(item.kind)} />
                      </Col>
                    </Row>
                  ) : (
                    <Row key={index} style={{ marginBottom: 20 }}>
                      <Col span={24}>
                        <CardStatusDashboard posts={item} cardType={getTypeOfPost(item.kind)} />
                      </Col>
                    </Row>
                  )
                ) : null
              )
            )}
          </Col>
        </Row>
      </Col>
      <Col span={8}>
        <Row gutter={[20, 20]}>
          {receiversData.data.length ? (
            <Col>
              <CardTopPoint isGiven={false} storeData={receiversData} />
            </Col>
          ) : (
            <></>
          )}
          {givenData.data.length ? (
            <Col>
              <CardTopPoint isGiven={true} storeData={givenData} />
            </Col>
          ) : (
            <></>
          )}
          <Col>
            <TopTrending />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default Dashboard;
