import React from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Skeleton from 'antd/lib/skeleton';
import dayjs from 'dayjs';

import { Flex } from 'src/styled/Layout';
import { Card as C, Text as T } from 'src/styled/Component';
import { Itop, Iuser } from 'src/interface/IActivitiesState';
import CardProfile from '@src/components/CardProfile';

interface Props {
  isGiven: boolean;
  storeData: Itop;
}

const CardTopPoint: React.FC<Props> = (props) => {
  const { data, isLoading } = props.storeData;

  if (isLoading) return <Skeleton active />;
  if (data.length === 0) return null;
  return (
    <C.CardNew notpointer>
      <Row gutter={[0, 18]}>
        <Col span={24}>
          <Flex between>
            <T.MainTitle
              fz={20}
              fw="500"
              style={{ display: 'inline-block' }}
              fm="'Poppins', sans-serif">
              Top Point {props.isGiven ? 'Giver' : 'Receivers'}
            </T.MainTitle>
          </Flex>
          <T.EnterSubTitle>{dayjs().format('MMMM YYYY')}</T.EnterSubTitle>
        </Col>
        {data.map((value: Iuser, index: number) => (
          <Col span={24} key={index}>
            <Flex full between itemcenter>
              <CardProfile
                id={value.id}
                name={value.name}
                team={value.team}
                avatar={value.avatar_url}
                top={index + 1}
              />
              <T.MainTitle fz={16}>
                {props.isGiven ? value.given_points : value.received_points}
              </T.MainTitle>
            </Flex>
          </Col>
        ))}
      </Row>
    </C.CardNew>
  );
};

export default CardTopPoint;
