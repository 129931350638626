import React from 'react';
import ContentLoader from 'react-content-loader';

const HeatmapChartMiniSkeleton: React.FC<{ heightChart: number }> = ({ heightChart }) => {
  return (
    <ContentLoader
      speed={2}
      width={'100%'}
      height={heightChart}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb">
      <rect x="25" y="10" rx="0" ry="0" width="1" height={heightChart - 1} />
      <rect x="25" y={heightChart - 1} rx="0" ry="0" width="80%" height="1" />

      <rect x="45" y="40" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="90" y="60" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="135" y="30" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="180" y="80" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="225" y="120" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="270" y="10" rx="5" ry="5" width="10" height={heightChart} />
      <rect x="315" y="20" rx="5" ry="5" width="10" height={heightChart} />
    </ContentLoader>
  );
};

export default HeatmapChartMiniSkeleton;
