import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';

import { IAppState } from 'src/interface/IAppState';
import { getUserId } from 'src/common/utils';
import { requestDataHeatmap, requestListProject } from 'src/components/Chart/heatmapAction';
import MiniStackBarContainer from '@src/components/Chart/StackBar/MiniStackBarContainer';
import HistoryMiniContainer from '@src/components/Heatmap/history/HistoryMiniContainer';

const HeatmapMiniChart: React.FC = () => {
  const dispatch = useDispatch();

  const lastedPost = useSelector((state: IAppState) => state.formState.lastedPost);
  const [timeFromWeek, setTimeFromWeek] = useState('');
  const userId = getUserId();

  useEffect(() => {
    setTimeFromWeek(dayjs().day(1).startOf('date').format());
  }, []);

  useEffect(() => {
    const callApiData = (fromDate: string, toDate: string) => {
      if (!userId || fromDate === '') return;
      const data = { from_date: fromDate, to_date: toDate };
      dispatch(requestDataHeatmap(data));
    };

    dispatch(requestListProject());
    callApiData(timeFromWeek, dayjs(timeFromWeek).add(6, 'days').format());
  }, [timeFromWeek, lastedPost, userId, dispatch]);

  return (
    <Row id={'Chart-heatmap'}>
      <Col span={24}>
        <MiniStackBarContainer />
        <HistoryMiniContainer />
      </Col>
    </Row>
  );
};

export default HeatmapMiniChart;
