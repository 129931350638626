import Link from 'next/link';
import { getLinkProfile } from '@src/utils/getLinkProfile';
import { Image as I, Text as T } from '@src/styled/Component';
import { Flex } from '@src/styled/Layout';
import Space from 'antd/lib/space';
import React from 'react';
import { IprofileState } from '@src/interface/IProfileState';
import { useSelector } from 'react-redux';
import { IAppState } from '@src/interface/IAppState';
import { isOnline as isOnl } from '@src/activities/status/statusSelector';
import { icons } from '@src/static';

interface Props {
  id: number;
  name: string;
  team: string;
  avatar: string;
  top?: number;
}

const CardProfile: React.FC<Props> = (props: Props) => {
  const { id, name, team, avatar, top } = props;
  const profileData: IprofileState = useSelector((state: IAppState) => state.headerState);
  const isOnline: boolean = useSelector(isOnl(id));

  const renderTop = (index: number) => {
    switch (index) {
      case 1:
        return (
          <I.Medal>
            <img src={icons.Top1st} alt={''} />
          </I.Medal>
        );
      case 2:
        return (
          <I.Medal>
            <img src={icons.Top2nd} alt={''} />
          </I.Medal>
        );
      case 3:
        return (
          <I.Medal>
            <img src={icons.Top3rd} alt={''} />
          </I.Medal>
        );
      default:
        return null;
    }
  };

  return (
    <Space size={10}>
      <Link href={`/profile/${getLinkProfile(id, profileData.data.id)}`} legacyBehavior>
        <I.AvatarContainer isOnline={isOnline}>
          <I.Image src={avatar} w={32} />
          <div className={'overlay'}></div>
        </I.AvatarContainer>
      </Link>
      <Flex between col>
        <Link href={`/profile/${getLinkProfile(id, profileData.data.id)}`} legacyBehavior>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <T.SubTitle hover>{name}</T.SubTitle>
            {top && renderTop(top)}
          </div>
        </Link>
        <T.TinyText fz={12}>{team}</T.TinyText>
      </Flex>
    </Space>
  );
};

export default CardProfile;
