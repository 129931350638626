/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import Link from 'next/link';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { Flex } from '@src/styled/Layout';
import Space from 'antd/lib/space';

import { Card as C, Image as I, Text as T, Video as V } from '@src/styled/Component';
import { useSelector } from 'react-redux';
import { IprofileState } from '@src/interface/IProfileState';
import { IAppState } from '@src/interface/IAppState';
import { getLinkProfile } from '@src/utils/getLinkProfile';
import { isOnline } from '@src/activities/status/statusSelector';
import { FindTimeFromNow } from '@src/activities/NewsEventDetail/Interactive/TabUserInteractive/Formula';
import ReactHashtag from '@src/activities/status/hashtag';
import ImagesContainer from '@src/activities/images/ImagesContainer';
import VideoPlayer from '@src/components/VideoPlayer';
import YouTube, { Options } from 'react-youtube';
import Fabric from '@src/components/Fabric';
import { getYouTubeId, isYouTubeUrl } from '@src/common/utils';
import { INewestPost } from '@src/interface/INewestState';

const breakWord = {
  wordBreak: 'break-all'
} as React.CSSProperties;

interface Props {
  posts: INewestPost;
  cardType: string;
}

const CardStatusDashboard: React.FC<Props> = (props: Props) => {
  const { posts, cardType } = props;
  const profileData: IprofileState = useSelector((state: IAppState) => state.headerState);

  const isAuthorOnline: boolean = useSelector(isOnline(posts.author_id));

  if (!posts) return <div>...error</div>;
  const { id, user, total_point, like_count, comment_count } = posts;

  const youtubePlayerOptions: Options = {
    playerVars: {
      autoplay: 0
    }
  };

  const youtubeUrls = posts.content
    ? posts.content
        ?.trim()
        ?.replace(/\s/g, ' ')
        ?.split(' ')
        ?.filter(isYouTubeUrl)
        ?.map(getYouTubeId)
    : [];

  const hasImage = posts.images ? posts.images.length > 0 : false;
  const hasGiphy = posts.giphy ? true : false;
  const hasVideo = posts.videos ? posts.videos.length > 0 : false;

  let keyId = 0;
  return (
    <Link href={`/activity/${cardType}/${id}`} legacyBehavior>
      <C.CardNew hoverable transformable unselect csbt>
        <Row gutter={[0, 18]}>
          <Col span={24}>
            <Flex full between itemcenter>
              <Space size={10}>
                <Link
                  href={`/profile/${getLinkProfile(posts.author_id, profileData.data.id)}`}
                  legacyBehavior>
                  <I.AvatarContainer isOnline={isAuthorOnline}>
                    <I.Image src={user.avatar_url} w={32} />
                    <div className={'overlay'}></div>
                  </I.AvatarContainer>
                </Link>
                <Flex between col>
                  <Space size={10}>
                    <Link
                      href={`/profile/${getLinkProfile(posts.author_id, profileData.data.id)}`}
                      legacyBehavior>
                      <T.SubTitle hover>{posts && posts.user && posts.user.name}</T.SubTitle>
                    </Link>
                    {posts.feeling ? (
                      <T.TextFeeling>
                        is {posts.feeling.icon}&nbsp; {posts && posts.feeling && posts.feeling.name}
                      </T.TextFeeling>
                    ) : null}
                  </Space>
                  <T.TinyText fz={12} className="cursor-pointer">
                    {FindTimeFromNow(posts.created_at)}
                  </T.TinyText>
                </Flex>
              </Space>
            </Flex>
          </Col>
          <Col span={24}>
            <C.StatusContentCard>
              {posts.content && !posts.fabric && (
                <C.CardNormal className="card-normal" style={breakWord}>
                  <ReactHashtag
                    renderHashtag={(value: string) => {
                      keyId += 1;
                      return <C.HagtagCard key={'hashtag' + keyId}>{value}</C.HagtagCard>;
                    }}
                    renderMention={(value: string) => {
                      keyId += 1;
                      return (
                        <C.HagtagCard style={{ color: '#0B7BFF' }} key={'mention' + keyId}>
                          {value}
                        </C.HagtagCard>
                      );
                    }}
                    renderPoint={(value: string) => {
                      keyId += 1;
                      return (
                        <C.HagtagCard
                          style={{
                            color: '#6FCF97',
                            border: '1px solid #6FCF97',
                            borderRadius: 3,
                            padding: '1.5px 4px'
                          }}
                          key={`point${keyId}`}>
                          {value}
                        </C.HagtagCard>
                      );
                    }}>
                    {posts.content}
                  </ReactHashtag>
                </C.CardNormal>
              )}
              {((posts.images && posts.images.length > 0) || posts.giphy) && !posts.fabric ? (
                <ImagesContainer src={posts.images} isStatus unPreview gifphy={posts.giphy} />
              ) : (
                ''
              )}
              {posts.videos && posts.videos.length > 0 ? (
                <VideoPlayer
                  videoURL={
                    posts.videos[0].stream_url ? posts.videos[0].stream_url : posts.videos[0].url
                  }
                  animatedThumbnail={
                    posts.videos[0].animated_thumbnail
                      ? posts.videos[0].animated_thumbnail.url
                      : null
                  }
                  thumbnail={posts.videos[0].thumbnail.url}
                  preview={
                    posts.videos[0].preview_thumbnail ? posts.videos[0].preview_thumbnail.url : null
                  }
                  height={posts.videos[0].height}
                  width={posts.videos[0].width}
                  duration={posts.videos[0].duration}
                  previewFramesInterval={posts.videos[0].preview_frames_interval}
                />
              ) : (
                ''
              )}
              {
                // Check if this status has already had at least an image or a video before showing youtube link preview
                !(hasImage || hasGiphy || hasVideo) && youtubeUrls.length > 0 && (
                  <V.EmbedVideoContainer>
                    {/* @ts-ignore */}
                    <YouTube videoId={youtubeUrls[0]} opts={youtubePlayerOptions} />
                  </V.EmbedVideoContainer>
                )
              }
              {
                // fabric background status
                posts.fabric && <Fabric jsonData={posts.fabric} hideToolbar={true} maxWidth={500} />
              }
            </C.StatusContentCard>
          </Col>
          <div className="is-bottom-card">
            <Flex full between itemcenter className="scroll-over-x">
              <Space size={32}>
                <Flex full itemcenter>
                  <T.TinyText fz={14} color={'black'}>
                    {like_count} Likes
                  </T.TinyText>
                </Flex>
                <Flex full itemcenter>
                  <T.TinyText fz={14} color={'black'}>
                    {comment_count} Comments
                  </T.TinyText>
                </Flex>
                <Flex full itemcenter>
                  <T.TinyText fz={14} color={'black'}>
                    {total_point} Points
                  </T.TinyText>
                </Flex>
              </Space>
            </Flex>
          </div>
        </Row>
      </C.CardNew>
    </Link>
  );
};

export default CardStatusDashboard;
