import React, { useState, useRef } from 'react';
import { Video } from 'src/styled/Component';
import { icons } from '@src/static';
import VisibilitySensor from 'react-visibility-sensor';
import { lazyLoadingImages, lazyLoadingCss } from 'src/common/utils';
import VideoJS from './VideoJS';

interface Props {
  videoURL: string;
  thumbnail?: string;
  animatedThumbnail?: string;
  preview?: string;
  width?: number;
  height?: number;
  duration?: number;
  previewFramesInterval?: number;
}

const VideoPlayer: React.FC<Props> = (props: Props) => {
  const {
    videoURL,
    thumbnail,
    animatedThumbnail,
    width,
    height,
    preview,
    duration,
    previewFramesInterval
  } = props;
  const [playing, updatePlay] = useState(false);
  const minHeight = 480;
  const validHeight = height && Number(height) < minHeight;

  const handleVisibleInBrowserView = (isVisile: boolean) => {
    const videoOutOfScreen = !isVisile;
    if (videoOutOfScreen) {
      updatePlay(false);
    }
  };

  const imgRef = useRef(null);

  return (
    <VisibilitySensor onChange={handleVisibleInBrowserView}>
      <Video.VideoStatus playIcon={icons.IIconPlay} ref={imgRef}>
        <Video.VideoBgThumbnail
          src={thumbnail && lazyLoadingImages(thumbnail, imgRef)}
          className={thumbnail && lazyLoadingCss(thumbnail, imgRef)}
        />
        <VideoJS
          videoWidth={width}
          videoHeight={height}
          height={validHeight ? height : minHeight}
          videoURL={videoURL}
          thumbnail={thumbnail}
          animatedThumbnail={animatedThumbnail}
          playing={playing}
          preview={preview}
          duration={duration}
          previewFramesInterval={previewFramesInterval}
        />
      </Video.VideoStatus>
    </VisibilitySensor>
  );
};

export default VideoPlayer;
