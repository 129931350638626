import React, { useEffect, useState } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Skeleton from 'antd/lib/skeleton';
import { Flex } from '@src/styled/Layout';
import { Card as C, Text as T } from '@src/styled/Component';
import EmptyLateOffDay from './components/EmptyLateOffDay';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '@src/interface/IAppState';
import { actionTypes } from '@src/components/LateOffDay/lateOffDayAction';
import { ILateOffDayState } from '@src/interface/ILateOffDay';
import CardProfileLateAndOffDay from '@src/components/CardProfile/LateAndOffDay';
import Space from 'antd/lib/space';
import dayjs from 'dayjs';
import Modal from 'antd/lib/modal';

const LateOffDay: React.FC = () => {
  const dispatch = useDispatch();

  const lateOffDayData: ILateOffDayState = useSelector((state: IAppState) => state.lateOffDayState);
  useEffect(() => {
    dispatch({ type: actionTypes.GET_LIST_DATA_LATE_OFF_DAY_REQUEST });
  }, [dispatch]);

  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <C.CardNew height={'auto'} notpointer>
      <Row gutter={[0, 18]}>
        <Col span={24}>
          <Flex between>
            <T.MainTitle fz={24} style={{ display: 'inline-block' }}>
              Late & Off Day
            </T.MainTitle>
          </Flex>
          <T.EnterSubTitle>(Today)</T.EnterSubTitle>
        </Col>
        {lateOffDayData.isLoading ? (
          <Skeleton active />
        ) : lateOffDayData.data.length > 0 ? (
          <>
            {lateOffDayData.data.slice(0, 5).map((item) => (
              <Col span={24} key={item.id}>
                <Flex full between itemcenter>
                  <CardProfileLateAndOffDay item={item} />
                  <Space size={10}>
                    <Flex between col flexend>
                      <T.TinyText fz={12}>{dayjs(item.created_at).format('HH:mm')}</T.TinyText>
                      <T.StatusSubTitle isApproved={item.status === 'Approved'}>
                        {item.status ? item.status : 'Waiting'}
                      </T.StatusSubTitle>
                    </Flex>
                  </Space>
                </Flex>
              </Col>
            ))}
            {lateOffDayData.data.length > 5 ? (
              <Col span={24}>
                <Flex flexend>
                  <T.ActionText fz={12} onClick={showModal}>
                    View more
                  </T.ActionText>
                </Flex>
              </Col>
            ) : null}
          </>
        ) : (
          <EmptyLateOffDay />
        )}
      </Row>
      <Modal
        title={
          <div style={{ display: 'flex' }}>
            <b style={{ marginRight: 2 }}>Late & Off Day </b>
            <T.TinyText fz={12}>(today)</T.TinyText>
          </div>
        }
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={400}>
        <div style={{ padding: '0 5px', height: '50vh', overflowY: 'scroll' }}>
          <Row gutter={[0, 8]}>
            {lateOffDayData.data.map((item) => (
              <Col span={24} key={item.id}>
                <Flex full between itemcenter>
                  <CardProfileLateAndOffDay item={item} />
                  <Space size={10}>
                    <Flex between col flexend>
                      <T.TinyText fz={12}>{dayjs(item.created_at).format('HH:mm')}</T.TinyText>
                      <T.StatusSubTitle isApproved={item.status === 'Approved'}>
                        {item.status ? item.status : 'Waiting'}
                      </T.StatusSubTitle>
                    </Flex>
                  </Space>
                </Flex>
              </Col>
            ))}
          </Row>
        </div>
      </Modal>
    </C.CardNew>
  );
};

export default LateOffDay;
