/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import dayjs from 'dayjs';
import omit from 'lodash/omit';
import { BarItemProps, ResponsiveBar } from '@nivo/bar';

import { setMaxValueChart, themeCustom } from '@src/components/Chart/StackBar/formula';
import { getProjectList, handleDataResource } from '@src/components/Chart/formula';
import { IdataWorkingDay, IheatmapState } from 'src/interface/IHeatmapState';
import { StackBarMiniChart, TitleChart } from '@src/styled/Component/Chart';
import Flex from '@src/styled/Layout/Flex';

import { IAppState } from '@src/interface/IAppState';
import HeatmapChartMiniSkeleton from '@src/components/Skeleton/HeatmapChartMiniSkeleton';
import FormatTooltip from '@src/components/Chart/StackBar/FormatTooltip';
import { Button as B, Text as T } from '@src/styled/Component';
import Space from 'antd/lib/space';

interface CustomTooltipProps extends BarItemProps {
  data: any; // eslint-disable-line
}

const MiniStackBarContainer: React.FC = () => {
  const router = useRouter();
  const dataHeatmap: IheatmapState = useSelector((state: IAppState) => state.heatmapState);
  const { dataResource, timeStart, timeEnd, isLoading } = dataHeatmap;
  const dataResourceUser = handleDataResource(dataResource, timeStart, timeEnd);
  const { listProject, colorProject, idProject } = getProjectList(dataHeatmap.data);

  const customTooltip = (data) => {
    const models: Array<IdataWorkingDay> = [];
    let day = 0;
    let hour = 0;
    let dataWD: IdataWorkingDay;

    for (const item in data) {
      dataWD = {
        nameProject: item,
        hour: data[item]
      };

      if (listProject.includes(item)) models.push(dataWD);
      if (dataWD.nameProject === 'day') day = dataWD.hour;
      if (dataWD.nameProject !== 'day' && dataWD.nameProject !== 'date') hour += dataWD.hour;
    }

    return (
      <FormatTooltip
        day={day}
        dataWorking={models}
        hour={hour}
        color={colorProject}
        idProject={idProject}
      />
    );
  };

  const getColor = (id: string) => {
    return colorProject[id];
  };

  const formatAxisBottomValue = (value: number | string) => {
    return dayjs(value, 'MM/DD/YYYY').format('dd');
  };

  const BarItem = ({
    showTooltip,
    hideTooltip,
    data,
    width,
    height,
    x,
    y,
    color
  }: CustomTooltipProps) => {
    const dataValue = data.data;
    const newData = omit(dataValue, ['date', 'day']);
    const temp = Object.keys(newData).sort((a, b) => {
      return Number(b) - Number(a);
    });
    const keys = temp.slice(-1).pop();

    const handleTooltip = (e) => showTooltip(customTooltip(dataValue), e);
    const handleMouseEnter = (e) => showTooltip(customTooltip(dataValue), e);
    const handleMouseLeave = () => hideTooltip();
    const c = data.id === keys ? { rx: 5, ry: 5 } : { rx: 0, ry: 0 };
    const idRound = `${data.id}${data.indexValue}${data.value}`.replace(/\s+/g, '');

    return (
      <g transform={`translate(${x},${y})`}>
        <defs>
          <clipPath id={`round-corner-${idRound}`}>
            <rect x={0} y={0} width={width} height={height + 10} rx={c.rx} ry={c.ry} />
          </clipPath>
        </defs>
        <rect
          width={width}
          height={height}
          onMouseMove={handleTooltip}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{ opacity: 0.8, fill: color }}
          clipPath={`url(#round-corner-${idRound})`}
        />
      </g>
    );
  };

  const sortData = (data) => {
    return data.map((d) => {
      const ordered = {};
      Object.keys(d)
        .sort()
        .forEach(function (key) {
          ordered[key] = d[key];
        });
      return ordered;
    });
  };

  const newDataChart = sortData(dataResourceUser);
  const newListProjects = [
    ...listProject.sort((a, b) => {
      return Number(b) - Number(a);
    })
  ];

  const getWeekOfMonth = (): number => {
    const now = dayjs();
    const firstDayOfMonth = now.startOf('month');
    const firstDayOfWeek = firstDayOfMonth.clone().startOf('week');
    const offset = firstDayOfMonth.diff(firstDayOfWeek, 'days');

    return Math.ceil((now.date() + offset) / 7);
  };

  return (
    <StackBarMiniChart height={260} isLoading={isLoading}>
      <div style={{ margin: '0 25px', cursor: 'default' }}>
        <Flex between>
          <TitleChart>Heatmap</TitleChart>
          <Space align={'start'} size={6} direction={'horizontal'}>
            <B.DarkBtn w={92} h={30} onClick={() => router.push('/heatmap')}>
              Post
            </B.DarkBtn>
          </Space>
        </Flex>
        <T.EnterSubTitle>
          Week {getWeekOfMonth()} - {dayjs().format('MMMM YYYY')}
        </T.EnterSubTitle>
      </div>
      {isLoading && <HeatmapChartMiniSkeleton heightChart={150} />}
      {!isLoading && (
        // @ts-ignore
        <ResponsiveBar
          data={newDataChart}
          keys={newListProjects}
          indexBy={'day'}
          margin={{ top: 20, right: 0, bottom: 40, left: 0 }}
          padding={0.8}
          minValue={0}
          maxValue={setMaxValueChart(dataResourceUser)}
          colors={({ id }) => getColor(id)}
          innerPadding={0}
          tooltip={({ data }) => customTooltip(data)}
          borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          isInteractive
          axisTop={null}
          axisRight={null}
          axisBottom={{
            tickSize: 0,
            tickPadding: 20,
            tickRotation: 0,
            format: (value) => formatAxisBottomValue(value)
          }}
          axisLeft={{
            tickSize: 0,
            tickPadding: 10,
            tickRotation: 0
          }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
          enableLabel={false}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          theme={themeCustom}
          barComponent={BarItem}
          enableGridY={false}
        />
      )}
    </StackBarMiniChart>
  );
};

export default MiniStackBarContainer;
