import React, { createElement } from 'react';
import { parse } from './libregex';
import { breakLine } from 'src/common/utils';
import { parseCustomEmoji } from 'src/components/Emoji/config';

const defaultHashtagRenderer = (hashtag: any, onClick: any, index: number) => (
  <span key={index} onClick={(e: any) => (onClick ? onClick(hashtag, e) : null)}>
    {hashtag}
  </span>
);

const defaultCustomEmojiRender = (emojiShortcut: any) => {
  const emojiURL = parseCustomEmoji(emojiShortcut);
  if (!emojiURL) return <span>{emojiShortcut}</span>;
  return (
    <span>
      <img className="custom-emoji" alt={emojiShortcut} src={emojiURL} />
    </span>
  );
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const HashtagComponent = (props: any) => {
  const contents =
    typeof props.children === 'object' && props.children.length
      ? !isNaN(props.children.length)
        ? props.children[0]
        : breakLine(props.children)
      : breakLine(props.children);
  const hashtagRenderer = props.renderHashtag || defaultHashtagRenderer;
  const mentionRenderer = props.renderMention || defaultHashtagRenderer;
  const pointRenderer = props.renderPoint || defaultHashtagRenderer;
  const customEmojiRender = props.renderCustomEmoji || defaultCustomEmojiRender;
  const onHashtagClick = props.onHashtagClick;
  const parsed: any = parse(
    contents,
    hashtagRenderer,
    mentionRenderer,
    pointRenderer,
    customEmojiRender,
    onHashtagClick
  );

  return createElement('span', null, parsed);
};

export default HashtagComponent;
