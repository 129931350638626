import { Image as I, Text as T } from '@src/styled/Component';
import { Flex } from '@src/styled/Layout';
import Space from 'antd/lib/space';
import React from 'react';
import { ILateOffDay } from '@src/interface/ILateOffDay';
import Link from 'next/link';
import { getLinkProfile } from '@src/utils/getLinkProfile';
import { useSelector } from 'react-redux';
import { isOnline as isOnl } from '@src/activities/status/statusSelector';
import { IprofileState } from '@src/interface/IProfileState';
import { IAppState } from '@src/interface/IAppState';
import OffType from '@src/components/CardProfile/LateAndOffDay/Components/OffType';
import dayjs from 'dayjs';

interface dataOffType {
  type: string;
  time: string;
}

interface Props {
  item: ILateOffDay;
}

const CardProfileLateAndOffDay: React.FC<Props> = (props: Props) => {
  const { item } = props;
  const profileData: IprofileState = useSelector((state: IAppState) => state.headerState);
  const isOnline: boolean = useSelector(isOnl(item.user.id));

  const getOffTime = (offTime: number): string => {
    switch (true) {
      case offTime === 1:
        return offTime.toString() + ' hour';
      case offTime > 1:
        return offTime.toString() + ' hours';
      default:
        return (offTime * 60).toString() + ' minutes';
    }
  };

  const getFromTimeToTime = (fromTime: Date, toTime: Date): string => {
    return 'from ' + dayjs(fromTime).format('HH:mm') + ' to ' + dayjs(toTime).format('HH:mm');
  };

  const getOffType = (item: ILateOffDay): dataOffType => {
    switch (item.offtype) {
      case 'urgent_late':
        return {
          type: 'Urgent late',
          time: ''
        };
      case 'off':
        return {
          type: 'Off',
          time: ''
        };
      case 'come_late':
        return {
          type: 'Come late',
          time: getOffTime(item.offtime)
        };
      case 'leave_early':
        return {
          type: 'Leave early',
          time: getOffTime(item.offtime)
        };
      case 'go_out':
        return {
          type: 'Go out',
          time: getFromTimeToTime(item.starttime as Date, item.endtime as Date)
        };
      default:
        return {
          type: '',
          time: ''
        };
    }
  };

  return (
    <Space size={10}>
      <Link href={`/profile/${getLinkProfile(item.user.id, profileData.data.id)}`} legacyBehavior>
        <I.AvatarContainer isOnline={isOnline}>
          <I.Image src={item.user.avatar_url} w={32} />
          <div className={'overlay'}></div>
        </I.AvatarContainer>
      </Link>
      <Flex between col>
        <Link href={`/profile/${getLinkProfile(item.user.id, profileData.data.id)}`} legacyBehavior>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
            <T.SubTitle hover>{item.user.name}</T.SubTitle>
          </div>
        </Link>
        <OffType {...getOffType(item)} />
        <T.TinyText fz={12} color={'#4D5F79'} fw={500}>
          {item.reason}
        </T.TinyText>
      </Flex>
    </Space>
  );
};

export default CardProfileLateAndOffDay;
