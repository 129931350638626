import { CARD } from '@src/common/constants';

const getTypeOfPost = (kind: number): string => {
  switch (kind) {
    case 0: {
      return CARD.ANNOUNCEMENT;
    }
    case 1: {
      return CARD.NEWS;
    }
    case 2: {
      return CARD.STATUS;
    }
    default: {
      return CARD.ANNOUNCEMENT;
    }
  }
};

export { getTypeOfPost };
