import React from 'react';
import List from 'antd/lib/list';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { handleBreakline } from 'src/common/utils';
import { Flex } from 'src/styled/Layout';
import { Label as L, Text as T } from 'src/styled/Component';
import { IinforProject, IresourceProject } from 'src/interface/IHeatmapState';
import { handleData } from '@src/components/Heatmap/history/HistoryItem';
import { useRouter } from 'next/router';

interface Props {
  historyList: IresourceProject[];
  projects: IinforProject[];
}

const HistoryItem: React.FC<Props> = (props: Props) => {
  const router = useRouter();
  const { historyList } = props;
  const data = handleData(historyList);

  const Title = ({ data }: any) => {
    return (
      <Row align={'middle'} className={'records-history'}>
        <Col span={24}>
          <Flex full between itemcenter>
            <T.ProjectTitle
              color={data.color}
              height="16px"
              width="16px">{`[${data.id}] ${data.project}`}</T.ProjectTitle>
            <L.ColorLabel width={75} height={30} color={data.color} fontColor="#F9F9F9">
              {data.hour} hours
            </L.ColorLabel>
          </Flex>
        </Col>
      </Row>
    );

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <T.ProjectTitle color={data.color}>{`[${data.id}] ${data.project}`}</T.ProjectTitle>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <L.ColorLabel width={75} height={30} color={data.color}>
            {data.hour} hours
          </L.ColorLabel>
        </div>
      </div>
    );
  };

  return (
    <>
      <List
        style={{
          height: 'auto',
          overflowY: 'auto'
        }}
        itemLayout={'horizontal'}
        dataSource={data.slice(0, 4)}
        renderItem={(item: any) => (
          <List.Item style={{ border: 'none', padding: '6px 0' }}>
            <List.Item.Meta
              avatar={null}
              title={<Title data={item}></Title>}
              description={
                <div
                  style={{ paddingLeft: 20 }}
                  dangerouslySetInnerHTML={{
                    __html: handleBreakline(item.activity)
                  }}
                />
              }
            />
          </List.Item>
        )}
      />
      <Flex flexend>
        <T.ActionText fz={12} onClick={() => router.push('/heatmap')}>
          View more
        </T.ActionText>
      </Flex>
    </>
  );
};

export default HistoryItem;
