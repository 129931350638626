import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import get from 'lodash/get';
import dayjs from 'dayjs';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import { Flex } from '@src/styled/Layout';
import Space from 'antd/lib/space';

import { Button as B, Card as C, Image as I, Label as L, Text as T } from '@src/styled/Component';
import { BOOK_MARK, CARD } from '@src/common/constants';
import { lazyLoadingCss, lazyLoadingImages } from '@src/common/utils';
import { icons } from '@src/static';
import { useDispatch } from 'react-redux';
import { bookmarkPosts } from '@src/activities/NewsEventDetail/DetailAction';
import { INewestPost } from '@src/interface/INewestState';
import CardProfile from '@src/components/CardProfile';

interface Props {
  posts: INewestPost;
  cardType: string;
}

const CardDashboard: React.FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const history = useRouter();
  const { search } = history.query;
  const { posts, cardType } = props;
  const [isBookMark, setBookMark] = useState<boolean>(false);

  const handleClickBookMark = (e: React.MouseEvent<HTMLButtonElement>, idCard: number) => {
    const shouldRemoveItem = isBookMark && search === BOOK_MARK;

    dispatch(bookmarkPosts(!isBookMark, idCard.toString(), shouldRemoveItem));
    setBookMark(!isBookMark);
    e.preventDefault();
  };

  useEffect(() => {
    if (posts.review.is_bookmarked) {
      setBookMark(true);
    }
  }, [posts, cardType]);

  const imgRef = useRef(null);

  if (!posts) return <div>...error</div>;
  const {
    id,
    kind,
    title,
    author,
    review,
    tags,
    created_at,
    image,
    like_count,
    read_count,
    comment_count
  } = posts;

  return (
    <Link href={`/activity/${cardType}/${id}`} legacyBehavior>
      <C.CardNew hoverable transformable unselect csbt>
        <Row gutter={[0, 18]}>
          <Col span={24}>
            <Flex full between itemcenter>
              <CardProfile
                id={author?.id}
                name={author?.name}
                team={author?.team}
                avatar={author?.avatar_url}
              />
              {review && !review.is_clicked && (
                <L.NotiLabel backgroundColor={'#DD4040'} color={'#FFFFFF'}>
                  New
                </L.NotiLabel>
              )}
            </Flex>
          </Col>
          <Col span={24} className="contain-checked">
            <I.NewThumbnailContainer
              src={lazyLoadingImages(image, imgRef)}
              ref={imgRef}
              className={lazyLoadingCss(image, imgRef)}
            />
            {get(posts, 'review.is_read') && <C.CheckedCardNew />}
          </Col>
          {tags && kind === 1 && tags[0].name !== 'PersonalTopic' ? (
            <div style={{ fontSize: 12, color: '#EB5757', fontWeight: 600 }}>
              {dayjs(created_at).format('hh:mmA - MMM DD, YYYY').toUpperCase()}
            </div>
          ) : null}
          <Col span={24}>
            <Flex between>
              {tags && get(tags, '[0].color', false) && (
                <L.HashTag color={tags[0].color}>{tags[0].name.toUpperCase()}</L.HashTag>
              )}
            </Flex>
            <T.MainTitle fz={18} mt={10} className={'max-line-2'}>
              {title}
            </T.MainTitle>
            <T.TinyText fz={12} mt={10}>
              {created_at && dayjs(created_at).format('hh:mmA MMM DD, YYYY')}
            </T.TinyText>
          </Col>
          <div className="is-bottom-card">
            <Flex full between itemcenter className="scroll-over-x">
              <Space size={32}>
                <Flex full itemcenter>
                  <B.MentionImgBtn
                    src={
                      review && review.is_liked ? icons.HeartFull : icons.IconUnHeart
                    }></B.MentionImgBtn>
                  <T.TinyText fz={14}>{like_count}</T.TinyText>
                </Flex>
                <Flex full itemcenter>
                  <B.MentionImgBtn src={icons.IconMessage}></B.MentionImgBtn>
                  <T.TinyText fz={14}>{comment_count}</T.TinyText>
                </Flex>
                <Flex full itemcenter>
                  <B.MentionImgBtn src={icons.IconSee}></B.MentionImgBtn>
                  <T.TinyText fz={14}>{read_count}</T.TinyText>
                </Flex>
              </Space>
              {cardType === CARD.NEWS && (
                <B.MentionImgBtn
                  nonMr
                  src={isBookMark ? icons.IconBookmarkSave : icons.IconBookMark}
                  onClick={(event) => handleClickBookMark(event, id)}
                  className="on-top-face"
                />
              )}
            </Flex>
          </div>
        </Row>
      </C.CardNew>
    </Link>
  );
};

export default CardDashboard;
