import React from 'react';
import { Text as T } from '@src/styled/Component';

interface Props {
  type: string;
  time: string;
}

const OffType: React.FC<Props> = (props: Props) => {
  const { type, time } = props;

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <T.TinyText fz={13} color={'#8B8B8B'} fw={400}>
        {type} {time}
      </T.TinyText>
      {/*<T.TinyText fz={12} color={'rgba(51,51,51,0.8)'} fw={400} ml={10}>*/}
      {/*  {time}*/}
      {/*</T.TinyText>*/}
    </div>
  );
};

export default OffType;
