import React, { useEffect } from 'react';
import Row from 'antd/lib/row';
import Col from 'antd/lib/col';
import Skeleton from 'antd/lib/skeleton';

import { Flex } from '@src/styled/Layout';
import { Card as C, Text as T } from '@src/styled/Component';
import { IactivitiesState, Ihashtag } from '@src/interface/IActivitiesState';
import { actionTypes } from '@src/activities/activitiesAction';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from '@src/interface/IAppState';
import dayjs from 'dayjs';

const TopTrending: React.FC = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: actionTypes.REQUEST_DATA_POINT_HASHTAG });
  }, [dispatch]);
  const { hashtagData }: IactivitiesState = useSelector(
    (state: IAppState) => state.activitiesState
  );
  const { data, isLoading } = hashtagData;
  if (isLoading) return <Skeleton active />;
  if (data && data.length === 0) return null;
  return (
    <C.CardNew notpointer>
      <Row gutter={[0, 18]}>
        <Col span={24}>
          <Flex between>
            <T.MainTitle fz={24} style={{ display: 'inline-block' }}>
              Top Hashtags Trending
            </T.MainTitle>
          </Flex>
          <T.EnterSubTitle>{dayjs().format('MMMM YYYY')}</T.EnterSubTitle>
        </Col>
        {data.slice(0, 5).map((value: Ihashtag, index: number) => (
          <Col span={24} key={index}>
            <Flex full between itemcenter>
              <T.Normal className="top-trending__tag-name" fz={16}>
                {value.name}
              </T.Normal>
              <T.Normal fz={16}>{value.taggings_count}</T.Normal>
            </Flex>
          </Col>
        ))}
      </Row>
    </C.CardNew>
  );
};

export default TopTrending;
